<template>
    <div id="print-templates">
        <div class="row">
            <ProfileSidebar></ProfileSidebar>
            <div v-if="templates.length > 0" class="col-lg-9 col-xl-9">
                <b-card>
                    <b-button v-if="templates.length < 10" variant="primary" class="float-right" @click="createTemplate">Προσθήκη νέου</b-button>
                    <b-card-title>Πρότυπα εκτύπωσης</b-card-title>
                    <b-card-sub-title>Δείτε & επεξεργαστείτε τα πρότυπα εκτύπωσής σας</b-card-sub-title>
                    <b-alert v-if="templates.length === 10" variant="warning" class="mt-3 mb-3" show>Μπορείτε να έχετε έως 10 πρότυπα εκτύπωσης</b-alert>
                    <div v-if="templates.length > 0" class="col-md-12 mt-3">
                        <b-card  v-for="template in templates" :key="template.id" class="shadow-sm">
                            <b-card-text>

                                <div class="row">
                                    <div class="col-md-6">
                                        <b-badge v-if="template.isStarred" variant="warning">Προεπιλεγμένο πρότυπο</b-badge>
                                        <b-badge v-if="template.isSystem" variant="primary">Πρότυπο συστήματος</b-badge>
                                        <span class="small-size">Όνομα προτύπου:</span> {{template.name}}
                                    </div>
                                    <div class="col-md-2">
                                        <span v-if="!template.isSystem" class="small-size">Ενέργειες</span>
                                        <b-button  v-if="!template.isSystem" variant="warning" size="xs" @click="$router.push('/user/print-templates/'+template.id)" > Επεξεργασία</b-button>
                                        <b-button v-if="!template.isSystem" variant="danger" size="xs" class="ml-1" @click="deleteTemplate(template)"><i class="icon-trash"></i></b-button>
                                    </div>
                                    <div v-if="!template.isSystem && !template.isStarred" class="col-md-4">
                                        <span class="small-size">Προεπιλογή</span>
                                        <b-button variant="primary" size="xs" @click="markAsStarred(template)">
                                            Ορισμός ως προεπιλεγμένο
                                        </b-button>
                                    </div>

                                </div>

                            </b-card-text>
                        </b-card>
                    </div>

                </b-card>


            </div>
        </div>
    </div>
</template>

<script>
    import ProfileSidebar from "./ProfileSidebar";

    export default{

        components: {ProfileSidebar},
        page: {
            title: 'Λογαριασμός',
        },

        data() {
            return {
                user: this.$store ? this.$store.state.auth.currentUser : {} || {},
                templates: this.$store ? (this.$store.state.auth.printTemplates || []) : [] || []
            }
        },
        async created() {
            if(this.templates.length === 0){
                await this.$store.dispatch('auth/getPrintTemplates');
                this.templates = this.$store.state.auth.printTemplates;
            }
        },

        methods:{
            createTemplate(){
                this.$store.dispatch('auth/createPrintTemplate').then((res)=>{
                    this.$router.push({name: 'edit-print-template', params: {templateId: res.data.template.id}});
                }).catch(e => {
                    this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η δημιουργία του προτύπου απέτυχε.'});
                });
            },

            deleteTemplate(template){
                let message = {
                    title: 'Θέλετε σίγουρα να διαγράψετε το πρότυπο;',
                    body: 'Προσοχή. Δεν υπάρχει δυνατότητα επαναφοράς άπαξ και ολοκληρωθεί η διαγραφή.'
                };
                this.$dialog.confirm(message, {
                    loader: true,
                    okText: 'Διαγραφή',
                    cancelText: 'Ακύρωση',
                }).then((dialog) => {

                        this.$store.dispatch('auth/deletePrintTemplate', template ).then((res)=>{
                            dialog.close();
                        }).catch(() => {
                            this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η διαγραφή απέτυχε. Παρακαλώ δοκιμάστε ξανά.'});
                            dialog.close();
                        });

                }).catch(()=>{});
            },

            markAsStarred(template){
                let message = {
                    title: 'Θέλετε σίγουρα να το ορίσετε ως προεπιλεγμένο;',
                    body: 'Το πρότυπο αυτό θα είναι αρχικά επιλεγμένο στις εκτυπώσεις σας.'
                };
                this.$dialog.confirm(message, {
                    loader: true,
                    okText: 'Ορισμός ως προεπιλογή',
                    cancelText: 'Ακύρωση',
                }).then((dialog) => {

                    this.$store.dispatch('auth/setStarredPrintTemplate', template ).then((res)=>{
                        dialog.close();
                    }).catch(() => {
                        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η '});
                        dialog.close();
                    });

                }).catch(()=>{});
            }
        }
    }
</script>
